
export type ButtonLink = {
  link: string;
}

export type BaseButtonOptions = {

}
export type ButtonOptions = {
  width?: string;
  disabled?: boolean;
  type?: "link" | "close" | "normal"
  isLoading?: boolean;
  hoveredText?: string ;
  link?: string;
  close?: boolean;
  variant?: "outline" | "ghost";
};

export const defaultButtonSettings: ButtonOptions = {
  isLoading: false,
  disabled: false,
  width: `max-content`,
  type : "normal"
};
