import { computed, Directive, ElementRef, input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { CRenderModel } from './c-render.model';
import { AbstractControl } from '@angular/forms';
import { Roles } from '../../models/role.model';
import { distinctUntilChanged } from 'rxjs';
import { IAction } from '../../../components/ui/table/table.model';

@Directive({
  selector: '[solCRender]',
  standalone: true
})
export class CRenderDirective implements OnInit, OnDestroy {
  solCRender = input.required<CRenderModel>();
  hasViewPermissionOnly = computed(() => {
    return this.solCRender().viewPermissions &&
      this.solCRender().viewPermissions?.some(permission => this.authService.hasRole(Roles[permission])) &&
      !this.solCRender().permissions?.some(permission => this.authService.hasRole(Roles[permission])) &&
      !this.solCRender().managePermissions?.some(permission => this.authService.hasRole(Roles[permission]))
  })
  private hasView = false;
  // private controls: AbstractControl[] = [];
  // private viewContainerRefs: ViewContainerRef[] = [];

  constructor(
    private authService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
  }
  ngOnInit() {
    this.updateView();
  }

  ngOnDestroy() {
    // this.controls = [];
    // this.viewContainerRefs = [];
  }

  private updateView() {

    let hasPermission = false;

    if (this.solCRender().permissions && this.solCRender().permissions?.some(permission => this.authService.hasRole(Roles[permission]))) {
      hasPermission = true;
    } else if  (this.solCRender().managePermissions && this.solCRender().managePermissions?.some(permission => this.authService.hasRole(Roles[permission]))) {
      hasPermission = true;
    } else if (this.solCRender().viewPermissions && this.solCRender().viewPermissions?.some(permission => this.authService.hasRole(Roles[permission]))) {
      hasPermission = true;
    }

    if (!hasPermission) {
      if (this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    } else {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(
          this.templateRef
        );
        this.hasView = true;
        
      }
    }
  }
  addControl(control: AbstractControl) {
    if(control){
      // this.controls.push(control);
      if(this.hasViewPermissionOnly()){
        control.disable({ emitEvent: false });
        control.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
          if (status !== 'DISABLED') {
            control.disable({ emitEvent: false });
          }
        })
      }
    }
  }
  addViewContainerRef(viewContainerRef: ViewContainerRef) {
    // this.viewContainerRefs.push(viewContainerRef);
    if(this.hasViewPermissionOnly()){
      const element = viewContainerRef.element.nativeElement as HTMLElement;
      element.remove();

    }
  }

  changeUpdateToView<T>(actions: IAction<T>[]){
    if(this.hasViewPermissionOnly()){
      actions.forEach(action => {
        if(action.label.toLowerCase() === 'update'){
          action.label = 'View';
        }
      });
    }
  }
  




}