import { Directive } from '@angular/core';

@Directive({
  selector: '[solSkipCRender]',
  standalone: true
})
export class SkipCRenderDirective {

  constructor() { }

}
