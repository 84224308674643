<a
  *ngIf="options && options.link; else Btn"
  cButton
 [variant]="options.variant"
  [routerLink]="options.link"
  [style.width]="options.width?? defualtSettings.width"
  cLoadingButton
  [loading]="loading"
  [spinnerType]="'border'"
  [color]="btnType"
  [disabled]="loading || (options.disabled ?? defualtSettings.disabled)"
>
	<ng-container *ngTemplateOutlet="Content"></ng-container>
</a>

<ng-template #Btn>
	<button
		[style.width]="options?.width ?? defualtSettings.width"
		cLoadingButton
		[loading]="loading"
		cButton
		
		[variant]="options?.variant"
		[disabled]="loading || (options?.disabled ?? defualtSettings.disabled)"
		(click)="handleClick()"
		id="button"
		[color]="btnType"
	>
		<ng-container *ngTemplateOutlet="Content"></ng-container>
	</button>
</ng-template>

<ng-template #Content>
	<ng-content></ng-content>
</ng-template>
